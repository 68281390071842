<template>
  <div>
    <HeroSmall :page_title="page_title" :leadline="leadline" />
    <section class="section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="row row-grid">
                            <div class="col-lg-6">
                                <!-- <card class="border-0" hover shadow body-classes="py-5"> -->
                                <card class="border-0" hover shadow body-classes="py-5 min-card-height">
                                    <icon name="fas fa-user-md" type="primary" rounded class="mb-4">
                                    </icon>
                                    <h3 class="text-primary text-uppercase">Current Openings</h3>
                                    <p class="description mt-3">Looking for a company that respects you and your contributions?</p>
                                    <router-link to="/jobs/" class="btn btn-primary mt-4">View Openings</router-link>
                                </card>
                            </div>
                            <!-- <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="fas fa-leaf" type="success" rounded class="mb-4">
                                    </icon>
                                    <h3 class="text-success text-uppercase">Hospice</h3>
                                    <p class="description mt-3">Special care for maintaining and improving quality of life for those with terminal illnesses.</p>
                                    <router-link to="/services#hospice" class="btn btn-success mt-4">Learn More</router-link>
                                </card>
                            </div> -->
                            <div class="col-lg-6">
                                <card class="border-0" hover shadow body-classes="py-5 min-card-height">
                                    <icon name="fas fa-leaf" type="success" rounded class="mb-4">
                                    </icon>
                                    <h3 class="text-success text-uppercase">Application</h3>
                                    <p class="description mt-3">Fill out our on-line application or download a copy to apply for other positions that may not be currently available.</p>
                                    <router-link to="/employment/application" class="btn btn-success mt-4">Apply Now!</router-link>
                                </card>
                            </div>
                            <!-- <div class="col-lg-4">
                                <card class="border-0" body-classes="py-5">
                                    <icon name="fas fa-user-md" type="primary" rounded class="mb-4">
                                    </icon>
                                    <h3 class="text-primary text-uppercase">Skilled Nurses</h3>
                                    <p class="description mt-3">Visits by skilled nurses available for a range of services from injections to monitoring chemotherapy drugs.</p>
                                    <router-link to="/services#services" class="btn btn-primary mt-4">Learn More</router-link>
                                </card>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
  </div>
</template>

<script>
  import HeroSmall from '@/views/components/HeroSmall.vue'
  import Card from '@/components/Card.vue'

  import {
    jobsRef
  } from '@/firebase'
  export default {
    name: 'job',
    components: {
      HeroSmall,
      Card
    },
    data: () => ({
      page_title: 'Join our Team',
			leadline: 'HealthView Home Health &amp; Hospice',
      
    }),
    methods: {

    },
    created() {
      
    }
  }
</script>

<style>
.min-card-height{ 
    min-height: 360px ;
}
</style>